// cSpell:ignore Auths RBAC
import { action, computed, type IReactionDisposer, observable, reaction, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';

import { axiom } from '../axiom';
import { notification } from '../components/Notification';
import { queryClient } from '../contexts/TanstackQueryTrpc';
import { dashApi, type RolesList, type OnboardingPayload } from '../shared/dash';
import { axiomDebug } from '../util/axiomDebug';
import { cloneDeep } from '../util/cloneDeep';
import { pushOrGoBack } from '../util/LocationHistoryProvider';
import { cloneUnknown } from '../util/objects';
import { safeLocalStorage } from '../util/safeLocalStorage';

import { sortNamedItemCaseInsensitive } from './CommonStoreHelpers';
import { type License, type Org, OwnerRole, type PaymentStatusType, AxiomRoles } from './CommonTypes';
import { SimpleOperationsTracker, StoreBase } from './StoreBase';
import { userStatusStore } from './UserStatusStore';
import { viewStore } from './ViewStore';

const ORG_LOCAL_STORAGE_KEY = 'activeOrg';

const nullLicense: License = {
  monthlyIngestGb: 0,
  monthlyQueryGbHours: 0,
  storageAllowanceGB: 0,
  apiRateLimitPerSecond: 0,
  expiresAt: new Date().toUTCString(),
  id: 'unlicensed',
  issuedAt: new Date().toUTCString(),
  issuedTo: 'unlicensed',
  issuer: 'Axiom',
  maxAuditWindowSeconds: 60,
  maxDatasets: 0,
  maxEndpoints: 0,
  maxMonitors: 0,
  maxQueryWindowSeconds: 60,
  maxTeams: 0,
  maxUsers: 0,
  maxFields: 100,
  tier: 'personal',
  validFrom: new Date().toUTCString(),
  withAuths: ['local'],
  withRBAC: false,
  features: {},
  dataRetentionWindowSeconds: 0,
};

class OrgStore extends StoreBase {
  @observable
  public orgRoles: RolesList[] = [];

  public createOrgOp = new SimpleOperationsTracker(this, 'createOrg');
  public deleteOrgOp = new SimpleOperationsTracker(this, 'deleteOrg');
  public updateOrgNameOp = new SimpleOperationsTracker(this, 'updateOrgName');
  public getActiveOrgOnboardingStatus = new SimpleOperationsTracker(this, 'getActiveOrgOnboardingStatus');
  public refreshLicenseOps = new SimpleOperationsTracker(this, 'refreshLicense');
  public onboardingStatusOps = new SimpleOperationsTracker(this, 'getOnboardingStatus');
  public setOnboardingOps = new SimpleOperationsTracker(this, 'setOnboarding');
  public getRolesForOrgOps = new SimpleOperationsTracker(this, 'getRolesForOrg');

  // 🐉🐉🐉
  // CDEUTSCH: Add @observable on Jan 19, 2023. A bit nervous this could cause issues.
  @observable
  public orgs: Org[] = [];

  @observable
  public activeOrgCompletedOnboarding: boolean | undefined = undefined;

  private readonly orgReactionDisposer: IReactionDisposer;
  private readonly userStatusStoreReactionDisposer: IReactionDisposer;

  constructor() {
    super();

    makeObservable(this);

    // Syncs userStatusStore.orgs to this.orgs.
    this.userStatusStoreReactionDisposer = reaction(
      () => [userStatusStore.orgs],
      () => {
        this.orgs = (cloneUnknown(userStatusStore.orgs) || []).slice().sort(sortNamedItemCaseInsensitive); // Slice to make Mobx happy.
      },
      { fireImmediately: false }
    );

    this.orgReactionDisposer = reaction(
      () => [this.activeOrgId],
      () => {
        // Save activeOrgId whenever it changes.
        if (this.activeOrgId) {
          this.saveActiveOrgId();
          this.getCurrentOrgOnboardingStatus();
          // get org roles whenever active org changes
          this.getRolesForOrg();
        }

        void queryClient.cancelQueries();
        void queryClient.resetQueries();
      },
      { fireImmediately: true }
    );
  }

  public dispose() {
    super.dispose();
    this.orgReactionDisposer();
    this.userStatusStoreReactionDisposer();
  }

  @computed
  public get activeOrgId(): string | undefined {
    const match = matchPath<{ orgId: string; page?: string }>(viewStore.location.pathname, { path: '/:orgId/:page?' });
    const orgId = match?.params?.orgId;

    // Make sure the user has access to the org.
    if (this.orgs.find((org) => org.id === orgId)) {
      return orgId;
    }

    return undefined;
  }

  @computed
  public get isDemoOrg(): boolean {
    return this.activeOrgId === 'acme-2uqx';
  }

  @action.bound
  public getRolesForOrg(): void {
    // Don't fetch roles if we're in play mode.
    if (axiom.playMode) {
      return;
    }

    void this.operate(this.getRolesForOrgOps.operation, dashApi.getRolesForOrg(), (results: RolesList[]) => {
      this.orgRoles = results || [];
    });
  }

  @action.bound
  public getCurrentOrgOnboardingStatus() {
    this.activeOrgCompletedOnboarding = undefined;

    const request = dashApi.getOnboardingStatus();

    void this.operate(this.getActiveOrgOnboardingStatus.operation, request, (onboardingStatus) => {
      const orgId = this.activeOrgId;

      if (orgId === undefined) {
        this.activeOrgCompletedOnboarding = undefined;

        return;
      }

      const statuses = onboardingStatus;
      const isComplete = typeof statuses?.[orgId] == 'undefined' ? true : statuses?.[orgId]; // backend bug. when feature flag is disabled -> org could be missing from response
      this.activeOrgCompletedOnboarding = !!isComplete;

      return;
    });

    return;
  }

  @action.bound
  public setOnboarding(payload: OnboardingPayload) {
    const orgId = orgStore.activeOrgId;
    if (!orgId) {
      return;
    }

    void this.operate(
      this.setOnboardingOps.getOrAddOperation(orgId),
      dashApi.setOnboardingData(orgId, payload),
      () => {
        this.activeOrgCompletedOnboarding = true;
        this.getCurrentOrgOnboardingStatus(); // Refresh the onboarding statuses.
      },
      true
    );
  }

  @computed
  public get activeOrg(): Org | undefined {
    return this.orgs.find((org) => org.id === this.activeOrgId);
  }

  @computed
  public get activeOrgRole(): string | undefined {
    if (this.activeOrg) {
      return this.activeOrg.role;
    } else {
      return undefined;
    }
  }

  @computed
  public get isOrgOwner(): boolean {
    return this.activeOrg?.role === OwnerRole;
  }

  @computed
  public get activeLicense(): License {
    return this.activeOrg?.license || cloneDeep(nullLicense);
  }

  @computed
  public get activePaymentStatus(): PaymentStatusType | undefined {
    return this.activeOrg?.paymentStatus;
  }

  @action.bound
  public createOrg(name: string) {
    const request = dashApi.createOrg({ name: name });

    void this.operate(
      this.createOrgOp.operation,
      request,
      (org) => {
        const newOrg = org as unknown as Org;

        this.orgs.push(newOrg);

        pushOrGoBack(`/${newOrg.id}`);
      },
      true
    );
  }

  @action.bound
  public async updateOrgName(name: string) {
    const orgId = this.activeOrgId;
    if (!orgId) {
      return;
    }

    return this.operate(
      this.updateOrgNameOp.operation,
      dashApi.updateOrg(orgId, { name: name }),
      (org) => {
        notification.success(
          {
            message: 'Organization updated',
            description: `Organization name has been updated`,
          },
          {
            autoClose: 4500,
          }
        );
        this.orgs = [...this.orgs.filter((o) => o.id !== orgId), org];
      },
      true
    );
  }

  @action.bound
  public deleteOrg(id: string) {
    const org = this.orgs.find((oo) => oo.id === id);

    if (!org) {
      throw new Error(`Org ${id} not found`);
    }

    notification.success(
      {
        message: 'Deleting organization',
        description: 'The page will refresh when the organization is deleted.',
      },
      {
        autoClose: 4500,
      }
    );

    void this.operate(
      this.deleteOrgOp.operation,
      dashApi.deleteOrg(id),
      () => {
        this.orgs = this.orgs.filter((oo) => oo.id !== id);

        if (this.orgs.length > 0) {
          // Redirect to first org.
          const firstOrg = this.orgs[0];
          window.location.href = `/${firstOrg.id}/datasets`;
        } else {
          // Do a full page reload since there aren't any orgs left.
          window.location.href = '/';
        }
      },
      true
    );
  }

  @action.bound
  public refreshLicense() {
    const orgId = this.activeOrgId;
    if (!orgId) {
      return;
    }

    void this.operate(
      this.refreshLicenseOps.operation,
      dashApi.getOrgLicense(orgId),
      (license) => {
        if (this.activeOrg?.id === orgId) {
          // Force type conversion.
          this.activeOrg.license = license as unknown as License;
        }
      },
      true
    );
  }

  public getSavedActiveOrgId(): string | undefined {
    try {
      const activeOrgId = safeLocalStorage.getItem(ORG_LOCAL_STORAGE_KEY);
      // Make sure we have access to the saved Org in the case where we logged out and switched users amongst others.
      if (activeOrgId && this.orgs.find((org) => org.id === activeOrgId)) {
        return String(activeOrgId);
      }
    } catch (error) {
      // Noop
      // console.warn('Failed to load active Org Id from localStorage.');
    }

    return undefined;
  }

  private saveActiveOrgId() {
    safeLocalStorage.setItem(ORG_LOCAL_STORAGE_KEY, this.activeOrgId || '');
  }

  public dropSavedActiveOrg() {
    safeLocalStorage.removeItem(ORG_LOCAL_STORAGE_KEY);
  }

  @computed
  public get filteredOrgRoles() {
    const roles = this.orgRoles.filter((role) => {
      if (AxiomRoles.includes(role.id!)) {
        role.id = role.id!.replace('axiom-', '');
      }

      return role;
    });

    return roles;
  }
}

// export as a singleton because we should only ever use one
export const orgStore = new OrgStore();

axiomDebug(orgStore);
